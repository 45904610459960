import {TwitterIcon} from "../../icons/socials/Twitter";
import {TelegramIcon} from "../../icons/socials/Telegram";

export const navigation = {
    solutions: [
        {name: 'How it works', href: '#'},
        {name: 'Documentation', href: 'https://jigsaw.gitbook.io/jigsaw-protocol'},
        // {name: 'API', href: '#'},
    ],
    support: [
        {name: 'Contact', href: 'mailto:admin@jigsaw.finance'},
        {name: 'Terms', href: '#'},
        {name: 'Privacy', href: '#'},
    ],
    social: [
        {
            name: 'Twitter',
            href: 'https://x.com/jigsawdefi',
            icon: (props) => (
                <TwitterIcon/>
            ),
        },
        {
            name: 'Telegram',
            href: 'https://t.me/+n2yB77BwHWZhMDEx',
            icon: (props) => (
                <TelegramIcon/>
            ),
        },
    ],
}
