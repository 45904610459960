import jbox from '../media/images/jigyar.png'
export function UnmatchedProtocol() {
    return (
        <section id="unmatched-protocol" aria-label="Unmatched Protocol">
            <div className="wrapper max-sm:px-5">
                <div className='w-1/4 h-1/4 rounded-full blur-[320px] bg-primary absolute bottom-[700px] z-30 max-sm:bottom-80'/>
                <h2 className='z-40'>Your unmatched protocol</h2>
                <div className="flex justify-center items-center flex-wrap relative gap-44 max-sm:gap-24">
                    <img src={jbox} alt='jigsaw swap mechanism' className="flex-grow-0 flex-shrink-0 w-1/3 h-1/3 object-cover max-sm:w-full max-sm:h-full max-sm:mx-5" />
                    <div className="flex flex-col justify-start items-start relative gap-10 max-sm:w-full max-sm:mx-5">
                        <p className="w-80 max-sm:w-full">
                            A lending protocol offering fluid and composable collateral.
                        </p>
                        <p className="w-80 max-sm:w-full">
                            Earn a yield on “fluid collateral” through an array of whitelisted protocols.
                        </p>
                        <p className="w-80 max-sm:w-full">
                            Complete control of the deposited collateral and acquired loans.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default UnmatchedProtocol
